<template>
  <div>
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="data">
              <router-link :to="'/automation/detail/' + data.item.id">
                <u> {{ data.item.name }}</u>
              </router-link>
            </template>
            <template v-slot:cell(valid_from)="data">
              <div>{{ data.item.valid_from | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.valid_from | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(valid_to)="data">
              <div>{{ data.item.valid_to | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.valid_to | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div
                :class="[
                  'd-flex justify-content-center',
                  data.item.active == 1 ? 'text-success' : 'text-danger',
                ]"
              >
                {{ $helper.status(data.item.active) }}
              </div>
            </template>
            <template v-slot:cell(goal_percen)="data">
              <span v-if="data.item.is_goal == '0'">- </span>
              <span v-else>
                {{ data.item.numGoal }} ({{ data.item.goal_percen }})
              </span>
            </template>
            <template v-slot:cell(is_draft)="data">
              <div class="d-flex justify-content-center">
                <span v-if="data.item.is_draft === 1">Draft</span>
                <span v-if="data.item.is_draft === 0" class="save-green"
                  >Publish</span
                >
              </div>
            </template>
            <template v-slot:cell(action)="data">
              <div class="d-flex justify-content-center">
                <router-link :to="'/automation/detail/' + data.item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
                <b-button
                  variant="link"
                  class="text-danger px-1 py-0"
                  @click="deleteData(data.item.id)"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    title="Delete"
                    class="main-color"
                  />
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        @handleChangeTake="hanndleChangePerpage"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      toShow: this.showingTo,
    };
  },
  methods: {
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("perpage", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.toShow = this.showing + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
    deleteData(id) {
      this.$emit("delete", id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.save-green {
  color: green;
}
</style>
